import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import "../../assets/css/blog.css";
import 'react-quill/dist/quill.snow.css';


function BlogDetails() {
  const baseUrl = process.env.REACT_APP_BASEURL;

  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/blog/${id}`);
        const data = await response.json();

        if (response.ok) {
          setBlog(data.data);
        } else {
          throw new Error(data.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <>
      <Navbar />
      <div className="mb-5">
        <Container className="my-5 mb-5">
          <Row className="main-blog-container">
            <Col className=" mt-5">
              <Link to="/blog">{"<<"} Back to blog </Link>
            </Col>
          </Row>

          <Row className="text-center mt-3">
            <Col className="text-center">
              <picture>
                <source srcSet={blog?.blogImage} media="(min-width: 1200px)" />

                <source
                  srcSet={blog?.blogImage}
                  media="(min-width: 800px) and (max-width: 1199px)"
                />

                <source srcSet={blog?.blogImage} media="(max-width: 799px)" />

                {/* Fallback image */}
                <img
                  src={blog?.blogImage}
                  alt={blog?.title}
                  className="mb-4 img-fluid products-image"
                  style={{
                    maxHeight: "400px",
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
              </picture>
            </Col>
          </Row>

          <Row className="">
            <Col>
              <h2 className="post-title mb-1">{blog?.title}</h2>
              <p className="blog-author">By {blog?.author} </p>
            </Col>
          </Row>

          <Row className="mt-3 ">
            <Col>
              <div   className="ql-editor"  dangerouslySetInnerHTML={{ __html: blog?.blogDetails }} />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default BlogDetails;
